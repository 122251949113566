import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Customer } from '../models/Customer';
import { CustomerHierarchy } from '../models/CustomerHierarchy';
import { CustomerMassUpdate } from 'src/app/models/CustomerMassUpdate';

import {
  filter,
  debounceTime,
  distinctUntilChanged,
  switchMap
} from 'rxjs/operators';
import { StartupService } from './startup.service';
import { CustomerRateOption } from '../models/CustomerRateOption';
import { CustomerConfiguration } from '../models/CustomerConfiguration';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  constructor(
    private http: HttpClient,
    private startupService: StartupService
  ) {}

  getByID(customerID: string): Observable<Customer> {
    return this.http.get<Customer>(
      `${this.startupService.ratingApiUrl}/api/customer/${customerID}`
    );
  }

  getCustomerByAccountNumber(accountNumber: string) {
    return this.http.get<Customer>(`${this.startupService.ratingApiUrl}/api/Customer/getbyaccountNumber?accountNumber=${accountNumber}`);
  }

  getCustomers(): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      `${this.startupService.ratingApiUrl}/api/customer/all`
    );
  }

  search(search$: Observable<string>): Observable<Customer[]> {
    return search$.pipe(
      filter(res => res.length > 2),
      debounceTime(1000),
      distinctUntilChanged(),
      switchMap(term => this.searchGet(term))
    );
  }

  getCustomerHierarchy(customerID: number): Observable<CustomerHierarchy[]> {
    return this.http.get<CustomerHierarchy[]>(
      `${this.startupService.ratingApiUrl}/api/customer/hierarchy?customerId=${customerID}`
    );
  }

  getCustomerChildren(customerID: number): Observable<CustomerHierarchy[]> {
    return this.http.get<CustomerHierarchy[]>(
      `${this.startupService.ratingApiUrl}/api/customer/children?customerId=${customerID}`
    );
  }
  
  private searchGet(searchString: string): Observable<Customer[]> {
    return this.http.get<Customer[]>(
      `${this.startupService.ratingApiUrl}/api/customer/search?searchString=${searchString}`
    );
  }
  createCustomer(customer: Customer){
    customer.isTestAccount = true;
    return this.http.post(`${this.startupService.ratingApiUrl}/api/customer`, customer);
  }

  updateCustomer(customer: Customer){
    customer.isTestAccount = true;
    return this.http.put(`${this.startupService.ratingApiUrl}/api/customer`, customer);
  }

  bulkUpdateCustomers(customerMassUpdate: CustomerMassUpdate){
    return this.http.put(`${this.startupService.ratingApiUrl}/api/customer/bulk`, customerMassUpdate);
  }

  insertCustomerRateOption(rateOption: CustomerRateOption){
    return this.http.post(`${this.startupService.ratingApiUrl}/api/CustomerRateOption`, rateOption);
  }

  getCustomerRateOptions(id: number){
    return this.http.get<CustomerRateOption[]>(`${this.startupService.ratingApiUrl}/api/CustomerRateOption/customer?customerID=${id}`);
  }

  editCustomerRateOption(rateOption: CustomerRateOption){
    return this.http.put(`${this.startupService.ratingApiUrl}/api/CustomerRateOption`, rateOption);
  }

  deleteCustomerRateOption(id: number){
    return this.http.delete(`${this.startupService.ratingApiUrl}/api/CustomerRateOption/${id}`);
  }

  getCustomerConfigurations(id: number){
    return this.http.get<CustomerConfiguration[]>(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration/all?customerID=${id}`);
  }

  insertNewCustomerConfiguration(customerConfig: CustomerConfiguration){
    return this.http.post(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration`, customerConfig);
  }

  updateCustomerConfiguration(customerConfig: CustomerConfiguration){
    return this.http.put(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration`, customerConfig);
  }

  deleteCustomerConfiguration(id: number){
    return this.http.delete(`${this.startupService.ratingApiUrl}/api/CustomerConfiguration/${id}`);
  }
}
