import { Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Carrier } from "src/app/models/Carrier";
import { AuthService } from "../../services/auth.service";
import { DropDownListComponent } from "@progress/kendo-angular-dropdowns/dist/es2015/dropdownlist.component";
import { CustomerAccessorialOverrideRule } from "../../models/CustomerAccessorialOverrideRule";

@Component({
  selector: "app-carrier-dropdown",
  templateUrl: "./carrier-dropdown.component.html",
  styleUrls: ["./carrier-dropdown.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CarrierDropdownComponent),
      multi: true
    }
  ]
})
export class CarrierDropdownComponent implements OnInit, ControlValueAccessor {
  @Input() required: boolean;
  @Input() carriers: Carrier[];
  @Input() styles: any;

  @Output() valueChange = new EventEmitter<number>();

  @ViewChild("dropdownlist", { static: true }) public dropdownlist: DropDownListComponent;

  isBgEmployee: boolean = false;
  refCarrierID: number = null;

  constructor(
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isBgEmployee = this.authService.isBluegraceEmployee();
  }

  selectionChanged(event: Carrier) {
    this.onChange(event.refCarrierID);
    this.onTouched();

    if (!!this.valueChange) {
      this.valueChange.emit(event.refCarrierID);
    }
  }

  displayHumanFriendlyCarrierString(carrier: Carrier): string {
    return Carrier.humanFriendlyCarrierString(carrier, this.isBgEmployee);
  }


  /*
  * ControlValueAccessor implementations
  */
  disabled = false;
  onTouched: any;
  onChange: any;
  writeValue(refCarrierID: number): void {
    this.dropdownlist.reset();
    this.refCarrierID = refCarrierID;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /*
  * End of: ControlValueAccessor implementations
  */
}
